module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"uQQgETzRmNV6CEjRyyBUUwhE4M735VWA","devKey":"uQQgETzRmNV6CEjRyyBUUwhE4M735VWA","trackPage":false,"trackPageWithTitle":true,"manualLoad":true,"customSnippet":"(function(){var i=\"analytics\",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error(\"Segment snippet included twice.\");else{analytics.invoked=!0;analytics.methods=[\"trackSubmit\",\"trackClick\",\"trackLink\",\"trackForm\",\"pageview\",\"identify\",\"reset\",\"group\",\"track\",\"ready\",\"alias\",\"debug\",\"page\",\"screen\",\"once\",\"off\",\"on\",\"addSourceMiddleware\",\"addIntegrationMiddleware\",\"setAnonymousId\",\"addDestinationMiddleware\",\"register\"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if([\"track\",\"screen\",\"alias\",\"group\",\"page\",\"identify\"].indexOf(e)>-1){var c=document.querySelector(\"link[rel='canonical']\");n.push({__t:\"bpc\",c:c&&c.getAttribute(\"href\")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement(\"script\");t.type=\"text/javascript\";t.async=!0;t.setAttribute(\"data-global-segment-analytics-key\",i);t.src=\"https://cdn.segment.com/analytics.js/v1/\" + key + \"/analytics.min.js\";var r=document.getElementsByTagName(\"script\")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey=\"${writeKey}\";;analytics.SNIPPET_VERSION=\"5.2.1\";}})();"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"e6lrdjky","include_in_development":true,"delay_timeout":3000,"hide_default_launcher":true},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"id":"/","name":"Shine.fr","short_name":"Shine.fr","start_url":"/","background_color":"#f7f0eb","theme_color":"#ffffff","display":"standalone","icon":"./static/favicon/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"93379c0140dddc5ffa7ab0207a9cd819"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
