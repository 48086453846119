import { Select as ChakraSelect, SelectProps, chakra } from "@chakra-ui/react";
import { FC, Fragment, useEffect, useRef, useState } from "react";

const Select: FC<SelectProps & {
  dynamicWidth?: boolean;
  dynamicOffset?: number;
  dynamicLabel?: string;
}> = ({
  dynamicWidth,
  dynamicOffset = 0,
  dynamicLabel = "",
  children,
  ...props
}) => {
  const [width, setWidth] = useState(0);

  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (dynamicWidth) {
      setWidth((ref.current?.offsetWidth ?? 0) + dynamicOffset);
    }
  }, [dynamicWidth, dynamicOffset, dynamicLabel]);

  return (
    <Fragment>
      <ChakraSelect
        width={dynamicWidth ? width > 0 ? `${width}px` : "auto" : props.width ?? "auto"}
        {...props}
      >
        {children}
      </ChakraSelect>

      <chakra.span
        ref={ref}
        aria-hidden="true"
        position="absolute"
        top="-9999px"
        left="0"
      >
        {dynamicLabel}
      </chakra.span>
    </Fragment>
  );
}

export default Select;